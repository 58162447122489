<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="CNPJ"
          v-model="company.cnpj"
          :rules="rules.cnpj"
          v-mask="mask.cnpj"
          required
          @change="saveCnpj"
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="!!company.hasPartner">
        <v-switch
          label="Já possui contrato social?"
          @change="saveHasSocialContract"
          v-model="company.hasSocialContract"
        ></v-switch>

        <p v-show="!!company.hasSocialContract && !!company.hasPartner">
          {{ hasSocialContractHint }}
        </p>
        <input-file
          v-show="!!company.hasSocialContract && !!company.hasPartner"
          ref="contratoSocial"
          label="Enviar o contrato Social"
          label-send="Arquivo enviado com Sucesso!"
          v-model="company.idContratoSocial"
          :loading="loadingContratoSocial"
          :rules="rules.contratoSocial"
          @change="saveContratoSocial"
          required
        ></input-file>
      </v-col>
      <v-col cols="12" class="pt-2">
        <span>Tem Certificado Digital e-CNPJ A1 ou A3?</span>
        <base-bullet-info
          href="https://simplificador.com.br/blog/o-que-e-certificado-digital/"
        ></base-bullet-info>
        <v-radio-group
          v-model="company.hasDigitalCertificate"
          @change="saveHasDigitalCertificate"
          row
        >
          <v-radio label="Sim" value="yes"></v-radio>
          <v-radio label="Não" value="no"></v-radio>
          <v-radio label="Não sei informar" value="dont_know"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="senhaWeb"
          type="password"
          append-icon="lock"
          label="Senha Web Prefeitura"
          v-model="company.senhaWebPrefeitura"
          required
          :rules="rules.senhaWebPrefeitura"
          @change="senhaWebPrefeitura"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="k-bullet">
        <base-bullet-info
          class="k-bullet-left"
          href="https://simplificador.com.br/blog/esqueci-minha-senha-web/"
        ></base-bullet-info>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import cnpj from '@src/services/cnpj'
import InputFile from '@src/components/widget/InputFile.vue'

import { companyMethods, companyComputed } from '@state/helpers'

export default {
  name: 'company-migrate',
  components: {
    InputFile,
  },
  mounted() {},
  data() {
    return {
      rules: {
        contratoSocial: [
          (v) => {
            if (this.company.hasPartner) return true
            return !!v || 'Envie o seu contrato social'
          },
        ],
        cnpj: [
          (v) => !!v || 'Por favor coloque o CNPJ.',
          (v) => cnpj.validate(v) || 'CNPJ inválido.',
        ],
        senhaWebPrefeitura: [(v) => !!v || 'Por favor coloque a senha web'],
      },
      mask: {
        cnpj: cnpj.mask(),
      },
    }
  },
  computed: {
    ...companyComputed,
    hasSocialContractHint() {
      let hint =
        'Caso não possua enviaremos pelo correio um contrato social padrão nos modelos da Junta Comercial ou Cartório sem custo.'
      return this.company.hasSocialContract ? '' : hint
    },
  },
  methods: {
    ...companyMethods,
    saveCnpj(v) {
      this.sendToApi('cnpj', v)
    },
    async saveContratoSocial(file) {
      await this.$store.dispatch(
        'taxonomy/saveCompanyContratoSocialConstituicao',
        file
      )
      this.$refs.contratoSocial.validate()
    },
    saveHasSocialContract(v) {
      this.sendToApi('hasSocialContract', v)
    },
    saveHasDigitalCertificate(v) {
      this.sendToApi('hasDigitalCertificate', v)
    },
    senhaWebPrefeitura(v) {
      this.sendToApi('senhaWebPrefeitura', v)
    },
    sendToApi(fild, value) {
      let data = { [fild]: value }
      this.updateCompany(data)
    },
  },
}
</script>
